
@font-face {
  font-family: 'Avenir_Heavy';
  src: url('./assets/fonts/Avenir_Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir_Regular';
  src: url('./assets/fonts/Avenir_Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.container {
  background-color: #f4f9fd;
  min-height: 100vh;
  font-family: 'Avenir_Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {


}

.loginSubContainer {
  background-color: white;
  min-width: 700px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px 60px 20px;
  box-shadow: 0px 10px 60px #e3e7eb;
}

.welcomeText {
  color: black;
  display: block;
  font-size: 24px;
  padding-bottom: 5px;
  padding-top: 20px;
}

.welcomeSubText {
  color: #666;
  display: block;
  font-size: 20px;
  padding-bottom: 40px;
}

input {
  border: none;
  border-color: #ccc;
  background-color: #e5eaf8;
  padding: 15px;
  margin-bottom: 20px; /* Adjusted margin */
  border-radius: 15px; /* Adjusted radius */
  font-size: 15px; /* New style */
  font-family: 'Avenir_Regular';
  color: #9195a1;
  display: block;
  width: 60%;
}


.buttonContainer {
  background-color: #945be7;
  border-radius: 20px;
  border: none;
  color: white;
  width: 60%;
  padding: 15px;
  align-items: center;
  margin-bottom: 25px;
  font-family: 'Avenir_Heavy';
  box-shadow: 0px 20px 40px rgba(147, 91, 231, 0.3);
  margin-top: 30px;
  cursor: pointer;
}

.facebookButtonContainer {
  background-color: #387ce4;
  border-radius: 20px;
  border: none;
  color: white;
  width: 60%;
  padding: 15px;
  align-items: center;
  margin-bottom: 25px;
  font-family: 'Avenir_Heavy';
  box-shadow: 0px 20px 40px rgba(56, 124, 228, 0.3);
  margin-top: 30px;
  cursor: pointer;
}



.buttonContainer:hover {
  
}